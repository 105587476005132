import { TransactionResponse } from "@ethersproject/abstract-provider";
import { formatEther, parseUnits } from "@ethersproject/units";
import { Box, Modal } from "@mui/material";
import { useEthers, useTokenBalance } from "@usedapp/core";
import { constants, ethers } from "ethers";
import { useState } from "react";
import Select, { components, OptionProps } from "react-select";
import { registerTipBackend } from "../../../api/backend";
import ERC20ABI from "../../../api/erc20abi.json";
import { tokens } from "../../../api/tokens_bsc.json";
import { useShowAlert, useShowLoading } from "../../../hooks";
import { Post } from "../../../types";
import styles from "./TipPopup.module.css";

type TokenData = {
	address: string;
	chainId: number;
	decimals: number;
	logoURI: string;
	name: string;
	symbol: string;
	label?: string;
	value?: string;
};

const TipPopup: React.FC<{
	open: boolean;
	onClose: () => void;
	post: Post;
}> = ({ open, onClose, post }) => {
	const [tipAmount, setTipAmount] = useState("1");
	const [tokenSelected, setTokenSelected] = useState(tokens[0]);

	const { library, account } = useEthers();

	const balance = useTokenBalance(tokenSelected.address, account) || constants.Zero;

	const showLoading = useShowLoading();
	const showAlert = useShowAlert();

	async function handleTip() {
		const tipAmountWei = parseUnits(tipAmount, tokenSelected.decimals);

		showLoading(true);

		try {
			const contract = new ethers.Contract(
				tokenSelected.address,
				ERC20ABI,
				library?.getSigner()
			);
			const tx: TransactionResponse = await contract.transfer(
				post.author,
				tipAmountWei
			);

			const status = await registerTipBackend(tx.hash, post.uuid);

			if (status.ok) {
				showAlert("Tip sent! It will be visible in 1-5 minutes.", "info");
			} else {
				showAlert(
					"Couldn't register tip. Backend returned: " + (await status.text()),
					"error"
				);
			}
		} catch (e: any) {
			if ("data" in e && "message" in e.data) {
				showAlert(e.data.message, "error");
			} else if ("message" in e) {
				showAlert(e.message, "error");
			} else {
				showAlert("There was an error in sending your tip.", "error");
			}
		}

		showLoading(false);
	}

	return (
		<Modal open={open} onClose={onClose} sx={{ zIndex: 9999 }}>
			<Box
				sx={{
					position: "absolute",
					left: "50%",
					top: "50%",
					transform: "translate(-50%, -50%)",
					outline: "none",
				}}
			>
				<div className={styles.popupContainer}>
					<div className={styles.popupRow}>
						<Select
							className={styles.currencySelect}
							options={tokens}
							defaultValue={tokenSelected}
							components={{ Option }}
							isMulti={false}
							onChange={(newValue) => {
								console.dir(newValue?.address);
								newValue && setTokenSelected(newValue);
							}}
							getOptionLabel={(option) => option.name}
						/>
					</div>
					<div className={styles.popupRow}>
						<input
							type="number"
							className={styles.popupInput}
							placeholder="Amount"
							onChange={(e) => setTipAmount(e.target.value)}
							value={tipAmount}
						/>
						<div className={styles.currency}>{tokenSelected.symbol}</div>
						<div onClick={handleTip} className={styles.popupTip}>
							Send
						</div>

						<button
							className={styles.maxButton}
							onClick={() => setTipAmount(formatEther(balance))}
						>
							Max
						</button>
					</div>
				</div>
			</Box>
		</Modal>
	);
};

const Option = (props: OptionProps<TokenData, false>) => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				minWidth: "20rem",
			}}
		>
			<components.Option {...props}>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
						}}
					>
						<img
							src={props.data.logoURI}
							style={{
								height: "1rem",
								marginRight: "1rem",
							}}
							alt=""
						/>
						<div>{props.data.name}</div>
					</div>
					<div style={{ opacity: "50%" }}>{props.data.symbol}</div>
				</div>
			</components.Option>
		</div>
	);
};

export default TipPopup;
