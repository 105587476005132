import { useEthers } from "@usedapp/core";
import { useHistory } from "react-router-dom";
import { RiCloseFill } from "react-icons/ri";
import { stack as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import ConnectImage from "./ConnectButton.png";
import styles from "./HamburgerMenu.module.css";
import Disconnect from "./Disconnect.png";
import { useState } from "react";

export default function HamburgerMenu() {
	const [isMenuOpen, setMenuOpen] = useState(true);
	const { account, activateBrowserWallet, deactivate } = useEthers();
	const history = useHistory();
	return (
		<div className={styles.wrapper}>
			<RiCloseFill
				color="white"
				size={40}
				onClick={() => setMenuOpen((old) => !old)}
			/>
			<Menu
				noOverlay
				right
				customBurgerIcon={false}
				customCrossIcon={false}
				isOpen={isMenuOpen}
				width="100%"
			>
				<div className={styles.container}>
					<div style={{ margin: "0 auto" }}>
						<Link to="/" className={styles.dappLink}>
							<span style={{ color: "#F4D3A5" }}> My</span>Shib
						</Link>
					</div>
					<div className={styles.listElement}>
						<a
							href="https://shibariumdao.io/locker/"
							className={styles.dappLink}
						>
							<span style={{ color: "#F4D3A5" }}> Lock</span>Shib
						</a>
					</div>
					<div className={styles.listElement}>
						<a
							href="https://shibariumdao.io/rocket/"
							className={styles.dappLink}
						>
							<span style={{ color: "#F4D3A5" }}>Rocket</span>Shib
						</a>
					</div>
					<div className={styles.listElement}>
						<a href="https://swap.shibariumdao.io/" className={styles.dappLink}>
							<span style={{ color: "#F4D3A5" }}> Swap</span>Shib
						</a>
					</div>
					{!account && (
						<img
							src={ConnectImage}
							style={{
								width: "15rem",
								height: "auto",
								marginLeft: "2rem",
								marginTop: "2rem",
							}}
							onClick={() => activateBrowserWallet()}
							alt="Connect to wallet"
						/>
					)}
					{account && (
						<div onClick={() => history.push("/profile")}>
							{account && (
								<img
									src={Disconnect}
									style={{
										width: "15rem",
										height: "auto",
										marginLeft: "2rem",
										marginTop: "2rem",
									}}
									onClick={() => deactivate()}
									alt="Disconnect from wallet"
								/>
							)}
						</div>
					)}
				</div>
			</Menu>
		</div>
	);
}
