import ShibariumDAO from "./ShibariumDAO.png";
import FirstOn from "./FirstOn.png";
import styles from "./Navbar.module.css";
import { Link } from "react-router-dom";
const Navbar = () => {
	return (
		<div className={styles.container}>
			<Link to="/">
				<img src={ShibariumDAO} style={{ height: "5rem" }} alt="" />
				<img
					src={FirstOn}
					style={{ height: "3rem", marginLeft: "1rem" }}
					alt=""
				/>
			</Link>
		</div>
	);
};

export default Navbar;
