import PostImage from "./PostImage/PostImage";
import { useContext } from "react";
import { PostsContext } from "../App";
import Journey from "./Navigation/Journey/Journey";
import {
	trackWindowScroll,
	ScrollPosition,
} from "react-lazy-load-image-component";
import styles from "./MainPage.module.css";

const MainPage: React.FC<{ scrollPosition: ScrollPosition }> = ({
	scrollPosition,
}) => {
	const { posts } = useContext(PostsContext);

	return (
		<div className={styles.mainPage}>
			<Journey />
			{posts
				?.sort((a, b) => {
					if (a.uuid === "571d46d9-7fe3-486d-8777-1ec0f34eb7cb") return -1;
					if (b.uuid === "571d46d9-7fe3-486d-8777-1ec0f34eb7cb") return 1;
					return 0;
				})
				.map((post, idx) => (
					<PostImage
						key={idx}
						scrollPosition={scrollPosition}
						post={post}
					></PostImage>
				))}
		</div>
	);
};

export default trackWindowScroll(MainPage);
