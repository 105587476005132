import { useEthers, useTokenBalance } from "@usedapp/core";
import { constants } from "ethers";

const tokenAddress = "0xB197a6Fdd8AB825332eDEbA1AB5C4D1Bf97fb9A8";

const useShibBalance = () => {
	const { account } = useEthers();
	return useTokenBalance(tokenAddress, account) || constants.Zero;
};

const useIsHolder = () => {
	const shibBalance = useShibBalance();
	return shibBalance.gt(constants.Zero);
};

const useHasSpecialAccess = () => {
	const shibBalance = useShibBalance();

	return (
		shibBalance.gte(constants.WeiPerEther.mul(100_000))
	);
};

export { useIsHolder, useHasSpecialAccess };
