import { useState } from "react";
import { CommentT } from "../../../types";
import Comment from "./Comment";
import styles from "./Comments.module.css";

const Comments: React.FC<{ commentData: CommentT[]; }> = ({ commentData }) => {
	const [hideExcess, setHideExcess] = useState(true);
	const maxComments = 2;

	if (hideExcess && commentData.length > maxComments) {
		return (
			<div className={styles.container}>
				<div
					style={{ width: "100%", backgroundColor: "#3E3E3E", height: "1px" }}
				/>
				{commentData.slice(0, maxComments).map((comment, idx) => (
					<div key={idx}>
						<Comment data={comment} />
						<div
							style={{
								width: "100%",
								backgroundColor: "#3E3E3E",
								height: "1px",
							}}
						/>
					</div>
				))}
				<button
					className={styles.showHideButton}
					onClick={() => setHideExcess(false)}
				>
					Show {commentData.length - maxComments} more comments
				</button>
			</div>
		);
	}

	return (
		<div className={styles.container}>
			{commentData.map((comment, idx) => (
				<div key={idx} style={{ paddingTop: "0.5rem" }}>
					<div
						style={{
							width: "100%",
							backgroundColor: "#3E3E3E",
							height: "1px",
						}}
					/>
					<Comment data={comment} />
				</div>
			))}
			{commentData.length > maxComments && (
				<button
					className={styles.showHideButton}
					onClick={() => setHideExcess(true)}
				>
					Hide excess
				</button>
			)}
		</div>
	);
};

export default Comments;
