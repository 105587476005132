import { BigNumber, BigNumberish } from "ethers";
import { formatUnits } from "ethers/lib/utils";

export function prettyFormatAmount(amount_: BigNumberish, decimals: number = 18) {
  const amount = BigNumber.from(amount_);
  const baseString = formatUnits(amount, decimals);
  const [integer, decimal] = baseString.split(".");

  if (integer === "0" && (decimal === "0" || !decimal)) {
    return "0";
  }

  if (integer.length < 4) {
    return `${integer}.${decimal?.slice(0, 3)}`;
  }

  // add commas between every 3 digits
  const integerWithCommas = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${integerWithCommas}.${decimal?.slice(0, 2)}`;
}