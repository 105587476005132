import { Link } from "react-router-dom";
import Bookmark from "./bookmark.png";
import styles from "./Feedchoice.module.css";
import Home from "./Home.png";
import Message from "./message.png";
import Rocket from "./Rocket.png";
import Profile from "./user.png";

export default function FeedChoice() {
	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<p
					className={styles.header}
					style={{
						fontSize: "2rem",
						color: "white",
						marginLeft: "1rem",
						marginTop: "1rem",
					}}
				>
					<span style={{ color: "#F4D3A5" }}>My</span>Shib
				</p>
				{/* <Link to="/">
				<img
					src={Logo}
					alt="MyShib logo"
					className={[styles.logo].join(" ")}
				/>
			</Link> */}
				<div className={styles.menuItems}>
					<Link to="/">
						<div className={[styles.action].join(" ")}>
							<img src={Home} alt="Home" className={styles.icon} />
							<p className={styles.menuItem}>Home</p>
						</div>
					</Link>
					<Link to="/tubylec">
						<div className={[styles.action].join(" ")}>
							<img src={Rocket} alt="Explore" className={styles.icon} />
							<p className={styles.menuItem}>Tubylec Calls</p>
						</div>
					</Link>
					<Link to="/profile">
						<div className={[styles.action].join(" ")}>
							<img src={Profile} alt="Profile" className={styles.icon} />
							<p className={styles.menuItem}>Profile</p>
						</div>
					</Link>
					<div className={[styles.actionGrey].join(" ")}>
						<img src={Message} alt="Profile" className={styles.icon} />
						<p className={styles.menuItem}>Messages</p>
					</div>
					<div className={[styles.actionGrey].join(" ")}>
						<img src={Bookmark} alt="Profile" className={styles.icon} />
						<p className={styles.menuItem}>Bookmarks</p>
					</div>
				</div>
			</div>
		</div>
	);
}
