import { useEthers } from "@usedapp/core";
import { Link, useHistory } from "react-router-dom";
import ConnectImage from "./ConnectButton.png";
import Disconnect from "./Disconnect.png";
import styles from "./UserSide.module.css";
const UserSide = () => {
	const history = useHistory();
	const { account, activateBrowserWallet, deactivate } = useEthers();

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<Link to="/" className={styles.dappLink}>
					<span style={{ color: "#F4D3A5" }}> My</span>Shib
				</Link>
				<a href="https://shibariumdao.io/locker/" className={styles.dappLink}>
					<span style={{ color: "#F4D3A5" }}> Lock</span>Shib
				</a>
				<a href="https://shibariumdao.io/rocket/" className={styles.dappLink}>
					<span style={{ color: "#F4D3A5" }}>Rocket</span>Shib
				</a>
				<a href="https://swap.shibariumdao.io/" className={styles.dappLink}>
					<span style={{ color: "#F4D3A5" }}> Swap</span>Shib
				</a>
				{!account && (
					<img
						src={ConnectImage}
						style={{ width: "10rem", height: "auto" }}
						onClick={() => activateBrowserWallet()}
						alt="Connect to wallet"
					/>
				)}
				{account && (
					<div
						className={styles.profilePictureButton}
						onClick={() => history.push("/profile")}
					>
						{account && (
							<img
								src={Disconnect}
								style={{ width: "10rem", height: "auto" }}
								onClick={() => deactivate()}
								alt="Disconnect wallet"
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default UserSide;
