import { Tooltip } from "@mui/material";
import { useEthers } from "@usedapp/core";
import DOMPurify from "dompurify";
import { useMemo, useState } from "react";
import { LazyLoadImage, ScrollPosition } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";
import { postComment } from "../../api/comments";
import { prettyFormatAmount } from "../../api/strings";
import { useUsername } from "../../api/username";
import { useShowAlert, useShowLoading, useToggle } from "../../hooks";
import { CommentT, Post } from "../../types";
import ProfilePicture from "../ProfilePicture/ProfilePicture";
import Coins from "./coins.png";
import CommentIcon from "./commentIcon.png";
import AddComment from "./Comments/AddComment";
import Comments from "./Comments/Comments";
import styles from "./PostImage.module.css";
import TipPopup from "./TipPopup/TipPopup";

type PostImageT = {
	post: Post;
	scrollPosition: ScrollPosition;
	hideAction?: boolean;
};

const PostImage: React.FC<PostImageT> = ({
	post,
	scrollPosition,
	hideAction,
}) => {
	const [showAddComment, toggleAddComment] = useToggle(false);
	const [comments, setComments] = useState<CommentT[]>(post.comments);

	const [showTipPopup, setTipPopup] = useState(false);
	const tipsShibdao = useMemo(() => {
		const raw = post.tipsByToken["0xB197a6Fdd8AB825332eDEbA1AB5C4D1Bf97fb9A8".toLowerCase()];
		if (!raw) return null;
		return prettyFormatAmount(raw, 18);
	}, [post]);

	const showAlert = useShowAlert();
	const showLoading = useShowLoading();

	const { account, library } = useEthers();

	const authorUsername = useUsername(post.author);

	const isAudioPost = post.contentType?.startsWith("audio/");

	async function onCommentSubmit(newComment: string) {
		showLoading(true);

		// prevent empty comments
		if (!newComment) {
			showAlert("The comment can't be empty!", "error");
		} else if (!account || !library) {
			showAlert("You have to be logged in to add a comment.", "error");
		} else if (newComment.length > 280) {
			showAlert("Comment too long!", "error");
		} else if (
			!/^[\x20-\x7F]*$/.test(newComment) ||
			newComment.includes("scam")
		) {
			showAlert("The comment can contain only text.", "error");
		} else {
			try {
				const text = newComment.replaceAll("\n", "<br/>");

				const toSign = "Comment: " + text + "\nFor post: " + post.uuid;

				// post the comment using the api
				const result = await postComment(
					post.uuid,
					text,
					account,
					await library.getSigner().signMessage(toSign)
				);

				// if the posting went fine, add the comment to the local list
				// (to avoid fetching again)
				if (result.ok)
					setComments([...comments, { author: account, content: text }]);
				else
					showAlert(
						"There was an error while submitting your comment.",
						"error"
					);
			} catch (e) {
				showAlert("There was an error while submitting your comment.", "error");
			}
		}

		showLoading(false);
	}

	return (
		<div className={styles.post}>
			<div className={styles.container}>
				<div className={styles.creator}>
					<div className={styles.creatorInfo}>
						<Link to={`/user/${post?.author}`} className={styles.creatorNick}>
							{post.authorUsername || authorUsername}
						</Link>
						<div className={styles.creatorWallet}>{post?.author}</div>
					</div>
					<Link to={`/user/${post?.author}`} className={styles.profilePicutre}>
						{post.author && (
							<ProfilePicture address={post.author} height="2.8rem" />
						)}
					</Link>
				</div>
				<div
					className={styles.text}
					dangerouslySetInnerHTML={{
						__html: DOMPurify.sanitize(post.text, {
							USE_PROFILES: { html: true },
						}),
					}}
				></div>

				<div className={styles.mediaContent}>
					{post.file && !isAudioPost && (
						<LazyLoadImage
							alt=""
							effect="blur"
							src={"https://" + post.file}
							style={{ width: "100%", height: "100%" }}
							className={styles.image}
							scrollPosition={scrollPosition}
						/>
					)}
					{isAudioPost && post.file && (
						<audio controls>
							<source src={"https://" + post.file} />
							Your browser does not support the audio element.
						</audio>
					)}
				</div>
				<div
					style={{ width: "100%", backgroundColor: "#3E3E3E", height: "1px" }}
				/>
				<div id="renderTips">
					<div className={styles.Buttons}>
						<img
							src={CommentIcon}
							style={{ height: "1.5rem", width: "auto" }}
							alt="comment"
						/>
						<p style={{ marginLeft: "0.5rem", fontSize: "0.8rem" }}>
							{comments.length || "0"}
						</p>
						<img
							src={Coins}
							style={{ height: "1.5rem", width: "auto", marginLeft: "2rem" }}
							alt="tip"
						/>
						<p
							style={{
								marginRight: "auto",
								marginLeft: "0.5rem",
								fontSize: "0.8rem",
								fontWeight: tipsShibdao ? "bold" : "initial",
								color: tipsShibdao ? "#f4d3a5" : "white",
							}}
						>
							{tipsShibdao || "0"} SHIBDAO
						</p>
						{/* <Tips amounts={{ ethereum: post.balance }} /> */}
						{!hideAction && (
							<div className={styles.viewerAction}>
								{account === post.author ? (
									<Tooltip title={`You can't tip your own post.`}>
										<div className={[styles.buttonTip, styles.buttonOff].join(" ")}>Tip</div>
									</Tooltip>
								) : (
									<button
										className={[styles.buttonTip, styles.animation].join(" ")}
										onClick={() => setTipPopup(true)}
									>
										Tip
									</button>
								)}

								<button
									className={[styles.buttonBlack, styles.animation].join(" ")}
									onClick={toggleAddComment}
								>
									Add Comment
								</button>
							</div>
						)}
					</div>
					<div
						style={{
							borderBottom: "0.1vrem solid #535353",
							clear: "both",
							paddingRight: "2rem",
							paddingLeft: "2rem",
						}}
					>
						{showAddComment && <AddComment onSubmit={onCommentSubmit} />}
					</div>
				</div>
			</div>

			<Comments commentData={comments} />
			<TipPopup
				open={showTipPopup}
				onClose={() => setTipPopup(false)}
				post={post}
			/>
		</div>
	);
};

export default PostImage;
