import { shortenAddress } from "@usedapp/core";
import { useEffect, useState } from "react";
import { getUsernameBackend } from "./backend";

const expandUsername = (address: string) => {
	try {
		return shortenAddress(address);
	} catch (e) {
		// first 10 characters
		return address.slice(0, 10);
	}
};

const useUsername = (address: string | undefined | null) => {
	const [username, setUsername] = useState("");

	useEffect(() => {
		setUsername(expandUsername(address || ""));
		if (!address) return;
		getUsernameBackend(address).then((res) => {
			if (res) setUsername(res);
		});
	}, [address]);

	return username;
};

export { expandUsername, useUsername };
