import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEthers } from "@usedapp/core";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "../src/components/Navbar/Navbar";
import {
	fetchAllPostsBackend,
	fetchAllTubylecPostsBackend,
} from "./api/backend";
import "./App.css";
import Flare from "./BigFlare.png";
import HamburgerMenu from "./components/HambugerMenu/HamburgerMenu";
import MainPage from "./components/MainPage";
import FeedChoice from "./components/Navigation/Feedchoice/Feedchoice";
import UserSide from "./components/Navigation/UserSide/UserSide";
import Profile from "./components/Profile/Profile";
import TubylecFeed from "./components/TubylecFeed/TubylecFeed";
import UniversalAlertProvider from "./components/UniversalAlert/UniversalAlertProvider";
import UserFeed from "./components/UserFeed/UserFeed";
import { Post } from "./types";

export const PostsContext = React.createContext<{
	posts: Post[];
	tubylecPosts: Post[];
}>({
	posts: [],
	tubylecPosts: [],
});
export const NotificationsContext = React.createContext<any[]>([]);

function App() {
	const { account } = useEthers();

	const [posts, setPosts] = useState<Post[]>([]);
	const [tubylecPosts, setTubylecPosts] = useState<Post[]>([]);

	useEffect(() => {
		(async () => {
			try {
				setPosts(await fetchAllPostsBackend());
			} catch (e) {
				console.log(e);
			}

			try {
				setTubylecPosts(await fetchAllTubylecPostsBackend(account || ""));
			} catch (e) {
				console.log(e);
			}
		})();

		return undefined;
	}, [account]);

	return (
		<Router>
			<UniversalAlertProvider>
				<ThemeProvider theme={createTheme({ palette: { mode: "light" } })}>
					<PostsContext.Provider
						value={{
							posts,
							tubylecPosts,
						}}
					>
						<div style={{ backgroundColor: "#131313" }}>
							<div
								className="App"
								style={{
									marginLeft: "auto",
									marginRight: "auto",
									maxWidth: 2000,
									position: "relative",
								}}
							>
								<img
									src={Flare}
									alt="Home"
									style={{
										position: "fixed",
										maxWidth: "90vw",
										maxHeight: "90vh",
										width: "90vw",
										height: "90vh",
										zIndex: 10,
										left: 0,
										top: 0,
										filter: "brightness(1.5)",
										right: 0,
										marginLeft: "auto",
										marginRight: "auto",
									}}
								/>
								<Navbar />
								<HamburgerMenu />
								<UserSide />
								<FeedChoice />
								<div className="ContentContainer">
									<Switch>
										<Route exact path="/">
											<MainPage />
										</Route>
										<Route exact path="/user/:address">
											<UserFeed />
										</Route>
										<Route exact path="/tubylec">
											<TubylecFeed />
										</Route>
									</Switch>
									{account && (
										<Route exact path="/profile">
											<Profile walletAddress={account} author={account} />
										</Route>
									)}
								</div>
							</div>
						</div>
					</PostsContext.Provider>
				</ThemeProvider>
			</UniversalAlertProvider>
		</Router>
	);
}

export default App;
