import styles from "./TubylecFeed.module.css";

export default function NoAccess() {
	return (
		<div className={styles.mainPage}>
			<div className={styles.container}>
				<div className={styles.JourneyPost}>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "100%",
							width: "100%",
							flexDirection: "column",
							gap: ".2rem",
						}}
					>
						<h2>No access!</h2>
						<p>
							You need to hold at least 100 000 SHIBDAO to view this page.
						</p>
						<a
							href="https://app.uniswap.org/#/swap?use=v1&outputCurrency=0xB197a6Fdd8AB825332eDEbA1AB5C4D1Bf97fb9A8&inputCurrency=ETH"
							target="_blank"
							rel="noreferrer"
							style={{
								color: "white",
								textDecorationLine: "underline",
							}}
						>
							Buy SHIBDAO
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
