import { useContext } from "react";
import {
	ScrollPosition,
	trackWindowScroll,
} from "react-lazy-load-image-component";
import { useHasSpecialAccess } from "../../api/holder";
import { PostsContext } from "../../App";
import PostImage from "../PostImage/PostImage";
import NoAccess from "./NoAccess";
import styles from "./TubylecFeed.module.css";

const MainPage: React.FC<{ scrollPosition: ScrollPosition }> = ({
	scrollPosition,
}) => {
	const { tubylecPosts } = useContext(PostsContext);
	const hasAccess = useHasSpecialAccess();

	if (!hasAccess) {
		return <NoAccess />;
	}

	return (
		<div className={styles.mainPage}>
			{tubylecPosts?.map((post, idx) => (
				<PostImage
					key={idx}
					scrollPosition={scrollPosition}
					post={post}
				></PostImage>
			))}
		</div>
	);
};

export default trackWindowScroll(MainPage);
